import React from 'react';

class MainText extends React.Component {
    render() {
      return <div >
      <p className="describe">Detta är Grupperas sida för att spåra pennor. Om du hiitat en av Grupperas pennor så kan du skriva 
      in pennans ID, det vill säga den sista delen av URLn som finns på pennan. Om du gör detta kommer du vidare till sidan för själva 
      pennan. Nedan så ser du en karta med senaste positionen för pennorna som angivits!</p>   
    </div>
    }
  }

  export default (MainText)