import React from 'react';

class NameText extends React.Component{
    render() {
        return <div className="describe">
            <p>På denna sida ska du fylla i ditt namn för att spara din plats och namn i listan!
            </p>

        </div>
    }
}

export default NameText