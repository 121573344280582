import React from 'react'

class HistoryText extends React.Component{
    render(){
        return <div className="describe">
            <p>Här nedan ser du vem som haft pennan hittills samt pennans resa på kartan!</p>
        </div>
    }
}

export default HistoryText